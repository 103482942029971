<template>
  <div class="industry">
    <!-- 顶部图 -->
    <div class="header-banner">
      <div class="container mx-auto title-box">
        <h1 class="banner-one-title">全域用户营销平台 赋能企业数字化变革</h1>
        <h1 class="banner-three-title">
          璐付通过与企业深度融合，为企业用户提供全面的产品数字化、场景营销和员工福利等服务，帮助企业根据不同需求场景，轻松选择活动方案，实现基于线上活动传播的高效引流及宣传推广。助力上游品牌方打通销售通路，提高数字权益商品流通效率；帮助企业消费端构建权益场景，实现营销创新，为企业数字化变革赋能，推动行业发展。
        </h1>
        <button class="btn-line">立即咨询</button>
      </div>
    </div>
    <!-- 案例展示 -->
    <div class="industry-introduce">
      <div class="container mx-auto">
        <h2 class="two-leave-title">案例展示</h2>
        <div class="tab-bar-menu">
          <ul class="flex">
            <li
              :class="[activeLiIndex === item.key && 'li-checked']"
              v-for="item in industryList"
              :key="item.key"
              @click="onClickIndustry(item.key)"
            >
              {{ item.title }}
            </li>
          </ul>
        </div>
        <div class="content-box">
          <div
            class="card flex wow animate__animated animate__fadeInUp"
            v-for="(item, index) in newDataList[pageIndex]"
            :key="index"
          >
            <div class="left">
              <img :src="item.url || require('@/assets/img/industry/1.jpg')" />
            </div>
            <div class="right flex flex-col justify-between" v-if="!item.checked">
              <p class="title">{{ item.title }}</p>
              <div>
                <div class="info flex">
                  <div class="label">合作客户</div>
                  <div class="value">{{ item.name }}</div>
                </div>
                <div class="info flex">
                  <div class="label">活动关键词</div>
                  <div class="value">{{ item.desc }}</div>
                </div>
                <div class="info flex">
                  <div class="label">关键词</div>
                  <div class="value">{{ item.keyWord }}</div>
                </div>
              </div>
              <p class="look-detail" @click="onLookDetail(index)">
                <!-- 查看详情 > -->
              </p>
            </div>
            <div class="right flex flex-col" v-else>
              <p class="title">积分商城回馈方案-提供整体外包服务：平台搭建、供应链、营销策划、售后服务</p>
              <div>
                <div class="info flex">
                  <div class="value">
                    <p>1、项目私有化部署，配合财富APP共同完成客户信息系统的建设。</p>
                    <p>2、会员权益兑换：积分商城对接多个增值服务类型权益，比如接送机，健康体检，以及慈善扶贫等。</p>
                    <p>
                      3、提供专线线上客服：用户在商城使用中，随时可以实现在线咨询，商品问题直接转给我方处理，售后问题
                      更为精准。
                    </p>

                    <p>
                      4、大转盘新玩法：通过会员权益的划分，在大转盘上也为客户提供了不同会员等级可以获取抽奖次数等奖励，
                      同时，奖品类型多样化，实现积分，虚拟产品和实物产品并存的奖品奖励模式。
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex justify-center align-center no-data" v-if="!isData">暂无数据</div>
          <div class="pagetion-box flex justify-center wow animate__animated animate__fadeInUp" v-if="isData">
            <Pagetion @change="onPagetonChange" :dataList="allData" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// components
import Pagetion from '@/components/common/Pagetion';

export default {
  components: {
    Pagetion
  },
  data() {
    return {
      dataList: [
        {
          key: 3,
          checked: false,
          title: '成都电信权益底层服务打造',
          name: '中国电信成都分公司',
          desc: '权益底层服务打造',
          keyWord: '权益活动, 定制化开发策划',
          url: require('@/assets/img/industry/1.jpg')
        },
        {
          key: 3,
          checked: false,
          title: '四川省移动高端用户权益底层服务支撑',
          name: '四川省移动',
          desc: '用户权益底层服务支撑',
          keyWord: '全球通用户专属权益活动',
          url: require('@/assets/img/industry/2.jpg')
        },
        {
          key: 3,
          checked: false,
          title: '重庆联通权益底层服务支撑',
          name: '中国联通重庆分公司',
          desc: '用户权益底层服务支撑',
          keyWord: '权益活动, 定制化开发策划',
          url: require('@/assets/img/industry/3.jpg')
        },
        {
          key: 3,
          checked: false,
          title: '重庆移动权益底层服务支撑',
          name: '中国移动重庆分公司',
          desc: '用户权益底层服务支撑',
          keyWord: '属地化权益活动, 定制化开发策划',
          url: require('@/assets/img/industry/4.jpg')
        },
        {
          key: 3,
          checked: false,
          title: '重庆电信属地化权益服务支撑',
          name: '中国电信重庆分公司',
          desc: '用户权益底层服务支撑',
          keyWord: '属地化权益活动, 定制化开发策划',
          url: require('@/assets/img/industry/5.jpg')
        },
        {
          key: 8,
          checked: false,
          title: '云闪付年度营销活动',
          name: '银联重庆分公司',
          desc: '云闪付年度营销活动',
          keyWord: '营销优惠活动, 定制化开发策划',
          url: require('@/assets/img/industry/6.jpg')
        },
        {
          key: 8,
          checked: false,
          title: '工商银行微信绑卡立减活动',
          name: '工商银行',
          desc: '微信绑卡立减活动',
          keyWord: '营销优惠活动',
          url: require('@/assets/img/industry/7.jpg')
        },
        {
          key: 8,
          checked: false,
          title: '招商银行车主加油权益活动',
          name: '招商银行',
          desc: '车主加油权益活动',
          keyWord: '营销优惠活动, 权益活动',
          url: require('@/assets/img/industry/8.jpg')
        },
        {
          key: 8,
          checked: false,
          title: '农商银行开门红活动',
          name: '农商银行',
          desc: '开门红活动',
          keyWord: '用户专属加油权益，开门红活动',
          url: require('@/assets/img/industry/9.jpg')
        },
        {
          key: 8,
          checked: false,
          title: '光大银行社群专属权益',
          name: '光大银行',
          desc: '社群专属权益',
          keyWord: '用户专属权益卡,刺激社群拉新,完成月活KPI',
          url: require('@/assets/img/industry/10.jpg')
        },
        {
          key: 8,
          checked: false,
          title: '三峡银行权益活动支撑',
          name: '三峡银行',
          desc: '权益活动支撑',
          keyWord: 'VIP观影',
          url: require('@/assets/img/industry/11.jpg')
        },
        {
          key: 8,
          checked: false,
          title: '哈尔滨银行用户权益',
          name: '哈尔滨银行',
          desc: '用户权益',
          keyWord: '重阳抽奖赠礼活动',
          url: require('@/assets/img/industry/12.jpg')
        },
        {
          key: 8,
          checked: false,
          title: '平安银行话费充值活动',
          name: '平安银行',
          desc: '话费充值活动',
          keyWord: '“话费88折”充值优惠活动',
          url: require('@/assets/img/industry/13.jpg')
        },
        {
          key: 5,
          checked: false,
          title: '阳光保险全国用户权益活动支撑',
          name: '阳光保险',
          desc: '全国用户权益活动支撑',
          keyWord: '用户专属权益',
          url: require('@/assets/img/industry/14.jpg')
        },
        {
          key: 5,
          checked: false,
          title: '太平洋保险&支付宝爆款活动',
          name: '太平洋保险&支付宝',
          desc: '太平洋保险&支付宝爆款活动',
          keyWord: '专属权益活动, 定制化开发策划',
          url: require('@/assets/img/industry/15.jpg')
        },
        {
          key: 5,
          checked: false,
          title: '爱车保用户权益解决方案',
          name: '爱车保',
          desc: '用户权益解决方案',
          keyWord: '积分商城开发, 权益活动策划',
          url: require('@/assets/img/industry/16.jpg')
        },
        {
          key: 9,
          checked: false,
          title: '成都电信权益底层服务打造',
          name: '龙湖珑珠',
          desc: '龙湖珑珠积分解决方案支撑',
          keyWord: '专属权益活动, 定制化开发策划',
          url: require('@/assets/img/industry/3.jpg')
        },
        {
          key: 9,
          checked: false,
          title: '长安汽车会员商城用户权益活动支撑',
          name: '长安汽车',
          desc: '用户权益活动支撑',
          keyWord: '专属权益活动, 定制化开发策划',
          url: require('@/assets/img/industry/18.jpg')
        },
        {
          key: 9,
          checked: false,
          title: '顺丰集团全国用户权益活动支撑',
          name: '顺丰集团',
          desc: '全国用户权益活动支撑',
          keyWord: '专属权益活动, 定制化开发策划',
          url: require('@/assets/img/industry/19.jpg')
        },
        {
          key: 9,
          checked: false,
          title: '华夏航空“逃跑计划”一路生花营销活动',
          name: '华夏航空',
          desc: '逃跑计划, 一路生花营销活动',
          keyWord: '营销活动',
          url: require('@/assets/img/industry/20.jpg')
        },
        {
          key: 9,
          checked: false,
          title: '万达全场景营销解决方案支撑',
          name: '万达集团',
          desc: '场景营销解决方案支撑',
          keyWord: '专属权益活动, 定制化开发策划',
          url: require('@/assets/img/industry/21.jpg')
        },
        {
          key: 9,
          checked: false,
          title: '成都电信权益底层服务打造',
          name: '鲁能地产',
          desc: '鲁能地产全场景解决方案',
          keyWord: '专属权益活动, 定制化开发策划',
          url: require('@/assets/img/industry/2.jpg')
        },
        {
          key: 9,
          checked: false,
          title: '成都电信权益底层服务打造',
          name: '高速ETC',
          desc: '高速ETC全国用户权益活动支撑',
          keyWord: '专属权益活动, 定制化开发策划',
          url: require('@/assets/img/industry/3.jpg')
        }
      ],
      industryList: [
        {
          title: '全部行业',
          key: 0
        },
        // {
        //   title: '金融行业',
        //   key: 1
        // },
        // {
        //   title: '地产行业',
        //   key: 2
        // },
        {
          title: '运营商',
          key: 3
        },
        // {
        //   title: '互联网行业',
        //   key: 4
        // },
        {
          title: '保险行业',
          key: 5
        },
        // {
        //   title: '政府单位',
        //   key: 6
        // },
        // {
        //   title: '汽车行业',
        //   key: 7
        // },
        {
          title: '银行行业',
          key: 8
        },
        {
          title: '其它行业',
          key: 9
        }
      ],
      activeLiIndex: 0,
      pageIndex: 0,
      drag: 10
    };
  },
  computed: {
    newDataList() {
      let old = JSON.parse(JSON.stringify(this.dataList));
      let arr = this.__arrChange__(5, old);
      if (this.activeLiIndex === 0) {
        return arr;
      } else {
        let newArr = this.dataList.filter(o => o.key === this.activeLiIndex);
        let lastArr = JSON.parse(JSON.stringify(this.__arrChange__(5, newArr)));
        return lastArr;
      }
    },
    isData() {
      return this.newDataList.length > 0;
    },
    allData() {
      let arr = JSON.parse(JSON.stringify(this.newDataList));
      return arr.flat();
    }
  },
  mounted() {
    let clientWidth = document.body.clientWidth;
    if (clientWidth > 1000) {
      new this.$wow.WOW().init({
        //新建实列
        boxClass: 'wow', //class名字
        animateClass: 'animate__animated', //animateclass动画库类名,和当前animate动画库决定
        offset: 0, //到元素距离触发动画（当默认为0）
        mobile: true, //在移动设备上触发动画（默认为true）
        live: true //对异步加载的内容进行操作（默认为true）
      });
    }
  },
  methods: {
    /* 事件#切换页面 */
    onPagetonChange(page) {
      console.log(page, 'page');
      this.pageIndex = page;
      this.onBackUp();
    },
    /** 事件#回到顶部 */
    onBackUp() {
      this.scrollToTop();
    },
    /** 事件#回到顶部 */
    scrollToTop() {
      // 距离顶部的距离
      const gap = document.documentElement.scrollTop || document.body.scrollTop;
      if (gap > 570) {
        window.requestAnimationFrame(this.scrollToTop);
        window.scrollTo(570, gap - gap / this.drag);
      }
    },
    /* 事件#查看详情 */
    onLookDetail(index) {
      this.dataList[index].checked = true;
    },
    /* 事件#选择行业 */
    onClickIndustry(index) {
      this.newDataList = [];
      this.activeLiIndex = index;
      this.pageIndex = 0;
    },
    /* 辅助#数组处理 */
    __arrChange__(num, arr) {
      const newArr = [];
      while (arr.length > 0) {
        newArr.push(arr.splice(0, num));
      }
      return newArr;
    }
  }
};
</script>

<style lang="less" scoped>
.industry {
  .header-banner {
    height: 483px;
    width: 100%;
    background-image: url('../../assets/img/industry-logo.png');
    .title-box {
      padding-top: 99px;
      .banner-one-title {
        margin-left: 45px;
        color: rgba(255, 255, 255, 1);
        letter-spacing: 0.08px;
        font-size: 40px;
      }
      .banner-three-title {
        width: 662px;
        color: rgba(255, 255, 255, 0.8);
        letter-spacing: 0.04px;
        font-size: 20px;
        margin-left: 45px;
      }
      .btn-line {
        margin-top: 80px;
        margin-left: 49px;
        border-radius: 6px;
        position: relative;
      }
      .btn-line:hover::after {
        content: '';
        display: block;
        width: 160px;
        height: 160px;
        top: 80px;
        left: -1px;
        position: absolute;
        background-image: url('../../assets/img/lupaywechat.png');
        background-size: 100% 100%;
        padding: 2px;
        border: 1px solid #f4f4f4;
        opacity: 1;
        transition-duration: 300ms;
      }
    }
  }
  &-introduce {
    margin-top: 77px;
    .two-leave-title {
      color: rgba(0, 0, 0, 0.8);
      font-size: 22px;
      margin-left: 49px;
    }
    .tab-bar-menu {
      margin-left: 49px;
      margin-top: 45px;
      ul {
        position: relative;
      }
      li {
        color: rgba(0, 0, 0, 0.8);
        font-size: 16px;
        margin: 0 24px;
        cursor: pointer;
        position: relative;
      }
      li:nth-child(1) {
        margin-left: 3px;
      }
      li:hover {
        color: @lupay-text-color-theme;
        font-size: 16px;
      }
      li:before {
        content: '';
        width: 25px;
        height: 2px;
        display: block;
        position: absolute;
        bottom: -8px;
        left: calc(50% - 12.5px);
        background-color: @lupay-bg-color-white;
        transform: scaleX(0);
        transition-duration: 300ms;
      }
      li:hover:before {
        content: '';
        width: 25px;
        height: 2px;
        display: block;
        position: absolute;
        bottom: -8px;
        left: calc(50% - 12.5px);
        background-color: @lupay-text-color-theme;
        transform: scaleX(1);
        transition-duration: 300ms;
      }
      .li-checked {
        color: @lupay-text-color-theme;
        transition-duration: 300ms;
      }
      .li-checked:before {
        content: '';
        width: 25px;
        height: 2px;
        display: block;
        position: absolute;
        bottom: -8px;
        left: calc(50% - 12.5px);
        background-color: @lupay-text-color-theme;
        transform: scaleX(1);
        transition-duration: 300ms;
      }
    }
    .content-box {
      .card {
        padding: 41px 0;
        border-bottom: 1px solid rgba(231, 238, 252, 1);
      }
      .left {
        min-width: 465px;
        min-height: 274px;
        // overflow: hidden;
        img {
          width: 465px;
          height: 274px;
          transition-duration: 300ms;
        }
        img:hover {
          transform: scale(1.1);
          transition-duration: 300ms;
        }
      }
      .right {
        margin-left: 41px;
        padding-bottom: 10px;
        .title {
          color: rgba(0, 0, 0, 0.8);
          font-size: 16px;
          background: rgba(224, 240, 255, 0.2);
          height: 52px;
          line-height: 52px;
        }
        .info {
          margin-bottom: 25px;
        }
        .label {
          color: rgba(0, 0, 0, 0.8);
          font-size: 14px;
          width: 100px;
        }
        .value {
          color: rgba(0, 0, 0, 0.6);
          font-size: 14px;
          p {
            line-height: 30px;
          }
        }
        .look-detail {
          color: rgba(33, 67, 174, 1);
          font-size: 14px;
          cursor: pointer;
        }
      }
      .pagetion-box {
        margin: 80px;
      }
    }
  }
  .fore-card {
    margin-top: 88px;
  }
  .no-data {
    height: 200px;
  }
}
</style>
