<template>
  <div class="pagetion">
    <div class="content flex align-center">
      <div class="left" @click="onPreview">
        <div class="crcil"></div>
      </div>
      <div class="card flex">
        <div
          :class="['number', activeIndex === index && 'number-selected']"
          v-for="(item, index) in total"
          :key="index"
          @click="onChangePage(index)"
        >
          {{ index + 1 }}
        </div>
      </div>
      <div class="right" @click="onNext">
        <div class="crcil"></div>
      </div>
      <p class="total-name">共{{ total }}页</p>
    </div>
  </div>
</template>

<script>
// 分页插件
export default {
  props: {
    dataList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      activeIndex: 0
    };
  },
  computed: {
    total() {
      return Math.ceil(this.dataList.length / 5) || 0;
    }
  },
  watch: {
    activeIndex(v) {
      this.$emit('change', v);
    }
  },
  methods: {
    /* 事件#上一页 */
    onPreview() {
      if (this.activeIndex !== 0) {
        this.activeIndex -= 1;
      }
    },
    /* 事件#上一页 */
    onNext() {
      if (this.activeIndex + 1 < this.total) {
        this.activeIndex += 1;
      }
    },
    /* 事件#选择当前页面 */
    onChangePage(index) {
      this.activeIndex = index;
    }
  }
};
</script>

<style lang="less" scoped>
.pagetion {
  .left {
    background: rgba(0, 0, 0, 0.2);
    width: 16px;
    height: 16px;
    cursor: pointer;
    border-radius: 50%;
    .crcil {
      position: relative;
      top: 30%;
      left: 35%;
      width: 6px;
      height: 6px;
      border-top: 1px solid #fff;
      border-right: 1px solid #fff;
      transform: rotate(-135deg);
    }
  }
  .right {
    cursor: pointer;
    width: 16px;
    height: 16px;
    background: rgba(0, 0, 0, 0.2);
    color: #fff;
    line-height: 16px;
    border-radius: 50%;
    text-align: center;
    .crcil {
      position: relative;
      top: 30%;
      left: 27%;
      width: 6px;
      height: 6px;
      border-top: 1px solid #fff;
      border-right: 1px solid #fff;
      transform: rotate(45deg);
    }
  }
  .left:hover {
    box-shadow: 0px 3px 10px rgba(34, 78, 219, 0.1);
  }
  .right:hover {
    box-shadow: 0px 3px 10px rgba(34, 78, 219, 0.1);
  }
  .card {
    margin: 0 20px;
    .number {
      width: 26px;
      height: 26px;
      border-radius: 50%;
      text-align: center;
      font-size: 14px;
      line-height: 26px;
      margin: 0 5px;
      cursor: pointer;
    }
    .number-selected {
      color: #fff;
      background: rgba(34, 78, 219, 1);
    }
  }
  .total-name {
    color: rgba(0, 0, 0, 0.8);
    font-size: 14px;
    margin: 0 20px;
    line-height: 24px;
  }
}
</style>
